const BASE_URL = process.env.REACT_APP_BACKEND_URL


export const authEndpoints = {
    LOGIN_IN: BASE_URL + '/auth/login',
    LOGOUT_IN: BASE_URL + '/auth/logout',
    SET_USER_IN: BASE_URL + '/auth/setUser',
    ADD_BANNER: BASE_URL + '/auth//add-banner',
    EDIT_BANNER: BASE_URL + '/auth/editBanner',
    GET_ALL_BANNERS: BASE_URL + '/auth/get-all-banners',
    REMOVE_BANNER: BASE_URL + '/auth/remove-banner',
    GET_ALL_FEATURE_FLAGS: BASE_URL + '/auth/get-all-feature-flags',
    REMOVE_FEATURE_FLAG: BASE_URL + '/auth/remove-feature-flag',
    EDIT_FEATURE_FLAG: BASE_URL + '/auth/editfeatureFlag',
    ADD_FEATURE_FLAG: BASE_URL + '/auth/add-feature-flag',
}

// Problem  Setter Management System Endpoints
export const problemSetterEndpoints = {
    GET_DETAILS: BASE_URL + "/problems/get/details/",
    GET_PUBLISHED_DETAILS: BASE_URL + "/problems/get/publishedDetails/",
    NEW_DRAFT: BASE_URL + "/problems/addNewDraft",
    DELETE_DRAFT: BASE_URL + "/problems/deleteDraft",
    SAVE_DRAFT: BASE_URL + "/problems/saveDraft",
    GET_DRAFT: BASE_URL + "/problems/getDraft/",
    SAVE_REVIEW_DRAFT: BASE_URL + "/problems/saveReview",
    GET_REVIEW_DRAFT: BASE_URL + "/problems/getReview/",
    EDIT_PUBLISHED: BASE_URL + "/problems/editPublish",
    SAVE_PUBLISHED: BASE_URL + "/problems/savePublish",
    NEW_PUBLISH: BASE_URL + "/problems/addNewPublish",
    GET_PUBLISHED_DRAFT: BASE_URL + "/problems/getPublished/",
    UPDATE_STATUS: BASE_URL + "/problems/updateStatus",
    RUN_SETTER_CODE: BASE_URL + "/problems/runSetterCode",
}

export const blogsEndpoints = {
    GET_ARTICLE: BASE_URL + '/blogs/getArticle',
    GET_PUBLISHED_ARTICLE: BASE_URL + '/blogs/getArticlePublished',
    DELETE_ARTICLE: BASE_URL + '/blogs/deleteArticle',
    GET_ALL_DRAFTS: BASE_URL + '/blogs/getAllDraft',
    GET_ALL_PENDING: BASE_URL + '/blogs/getAllPending',
    GET_ALL_PUBLISHED: BASE_URL + '/blogs/getAllPublished',
    GET_ALL_CATEGORY: BASE_URL + '/blogs/category/getAll',
    ADD_CATEGORY: BASE_URL + '/blogs/category/add',
    GET_ALL_TOPICS: BASE_URL + '/blogs/topics',
    NEW_BLOG: BASE_URL + '/blogs/createDraftArticle',
    SAVE_BLOG: BASE_URL + '/blogs/saveArticle',
    SUBMIT_BLOG: BASE_URL + '/blogs/submitArticle',
    REVERT_BLOG: BASE_URL + '/blogs/revertArticle',
    PUBLISH_BLOG: BASE_URL + '/blogs/publishArticle',
    UPDATE_PUBLISHED_BLOG: BASE_URL + '/blogs/updatePublished',
    GET_ALL_QUESTIONS: BASE_URL + '/question/getAllQuestions',
    GET_ALL_OS: BASE_URL + '/question/getAllOS',
    GET_ALL_DBMS: BASE_URL + '/question/getAllDBMS',
    GET_ALL_CN: BASE_URL + '/question/getAllCN',
    UPDATE_CORE_SHEET: BASE_URL + '/question/updateCoreSheet/',
    GET_ALL_QUESTION_TOPICS: BASE_URL + '/question/question-topics',
    SET_QUESTION_DIFF: BASE_URL + '/question/setDifficulty/',
    GET_ALL_COLLEGES: BASE_URL + '/question/getAllColleges',
    VERIFY_COLLEGE: BASE_URL + '/question/verifyCollege',
    DELETE_COLLEGE: BASE_URL + '/question/deleteCollege',
}

export const successStoriesEndpoints = {
    ADD_STORY: BASE_URL  + '/successStories/add',
    GET_ALL_STORIES: BASE_URL + '/successStories/getAllStories'
}
export const bugsEndpoints = {
    GET_ALL_BUGS: BASE_URL  + '/bugs/getAllBugs',
    CLOSE_BUG: BASE_URL  + '/bugs/close-bug',
}
export const emailEndpoints = {
    SEND_EMAIL_TO_ALL_USERS: BASE_URL + '/mails/send-email',
}