import { emailEndpoints } from '../api'
import { apiConnector } from "../apiConnector"
import toast from 'react-hot-toast'

const { SEND_EMAIL_TO_ALL_USERS } = emailEndpoints;

export function sendEmailToAllUsers(title, message) {
    return async () => {
        try {
            const response = await apiConnector("POST", SEND_EMAIL_TO_ALL_USERS, { title, message })
            if (response.data.success) {
                toast.success(response.data.message);
            } else {
                toast.error(response.data.message || "Failed to send emails");
            }
        } catch (error) {
            console.log(error)
            toast.error(error.response?.data?.message || "An error occurred");
        }
    }
}