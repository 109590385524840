import { combineReducers } from "@reduxjs/toolkit";

import authReducer from "../slices/authSlice";
import bugReducer from "../slices/bugSlice";
import problemReducer from '../slices/problemSlice';
import blogReducer from '../slices/blogSlice';
import questionReducer from '../slices/questionSlice';
import successStoriesReducer from '../slices/successStoriesSlice';
import emailReducer from '../slices/emailSlice';

const rootReducer = combineReducers({
    auth: authReducer,
    bug: bugReducer,
    problems: problemReducer,
    blogs: blogReducer,
    question: questionReducer,
    successStories: successStoriesReducer,
    emails: emailReducer,
});

export default rootReducer;
