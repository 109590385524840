/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import Header from '../../components/Header/Header';
import Sidebar from '../../components/Sidebar/Sidebar';
import toast from 'react-hot-toast';
import { sendEmailToAllUsers } from '../../services/operations/sendEmail';
import { Controller, useForm } from 'react-hook-form';
import ReactQuill from 'react-quill';
import { useDispatch } from 'react-redux';

const SendEmail = () => {
    const dispatch = useDispatch();
    const [sidebarOpen, setSidebarOpen] = useState(false);

    const [isSending, setIsSending] = useState(false);

    const { handleSubmit: handleEmailSubmit, control: emailControl, formState: { errors: emailErrors, isSubmitted: isEmailSubmitted }, reset } = useForm({
        defaultValues: {
            sendEmailTitle: '', 
            sendEmailMessage: '', 
        }
    });

    const handleSendEmail = async (data) => {
        // console.log('Form data:', data);
        const { sendEmailTitle, sendEmailMessage } = data;
        setIsSending(true);
        const toastId = toast.loading('Sending emails. Please wait...');
        try {
            dispatch(sendEmailToAllUsers(sendEmailTitle, sendEmailMessage));
            toast.success('Emails sent successfully!', { id: toastId });
        } catch (error) {
            toast.error('An error occurred while sending emails.', { id: toastId });
        } 
        setIsSending(false);
        reset({
            sendEmailTitle: '',
            sendEmailMessage: '',
        });
    }

    return (

        <div className="flex dark:bg-dark font-primary  h-screen overflow-hidden bg-[#fafafa]">
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                <main>
                    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                    <span className='text-2xl font-semibold text-light_30 dark:text-zinc-300'>Send Email to All Users</span>

                        <div className=' flex items-start justify-center'>
                            <form onSubmit={handleEmailSubmit(handleSendEmail)} className='mt-6'>
                                <div className="flex flex-wrap -mx-3 mb-4">
                                <span className='px-3 text-lg font-semibold text-light_30 mb-2 dark:text-zinc-300'>Subject for email</span>
                                    <div className="w-full px-3">
                                        <Controller
                                            name="sendEmailTitle"
                                            control={emailControl}
                                            render={({ field }) => (
                                                <input
                                                    id="sendEmailTitle"
                                                    type="text"
                                                    name="sendEmailTitle"
                                                    className="form-input w-full text-gray-300"
                                                    placeholder="Title"
                                                    {...field}
                                                />
                                            )}
                                            rules={{
                                                required: 'Title is required',
                                            }}
                                        />
                                        {isEmailSubmitted && emailErrors.sendEmailTitle && <p className="text-red-600 text-sm italic">{emailErrors.sendEmailTitle.message}</p>}
                                    </div>
                                </div>
                                <div className="flex flex-wrap -mx-3 mb-4">
                                <span className='px-3 text-lg font-semibold text-light_30 mb-2 dark:text-zinc-300'>Message</span>
                                    <div className="w-full px-3">
                                        <Controller
                                            name="sendEmailMessage"
                                            control={emailControl}
                                            render={({ field }) => (
                                                <ReactQuill
                                                    id="sendEmailMessage"
                                                    theme="snow"
                                                    name="sendEmailMessage"
                                                    className="w-full h-auto text-gray-300"
                                                    placeholder="Email message to be sent."
                                                    {...field}
                                                />
                                            )}
                                            rules={{
                                                required: 'Message is required',
                                            }}
                                        />
                                        {isEmailSubmitted && emailErrors.sendEmailMessage && <p className="text-red-600 text-sm italic">{emailErrors.sendEmailMessage.message}</p>}
                                    </div>
                                </div>
                            
                                <div className="flex flex-wrap -mx-3 mb-4">
                                    <div className="w-full px-3">
                                        <button type="submit" className="btn-brand-2 w-full">Send Email</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    )
}

export default SendEmail