import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    email: {},
};

const emailSlice = createSlice({
    name: "emails",
    initialState: initialState,
    reducers: {
        setEmail(state, value) {
            state.email = value.payload;
        }
    },
});

export const { setEmail } = emailSlice.actions;
export default emailSlice.reducer;